const videolist = [
  {
    published: true,
    num: "00",
    title: "朝日",
    desc: "とある日の朝日。いつもと変わらない栖本町の穏やかな日常の中に大切なものがあるのではないか。プロジェクトのオープニングに問いかけるシンプルなメッセージ。",
    vtag: '<iframe src="https://www.youtube.com/embed/V30RY8iZdCI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "01",
    title: "いつもの散歩",
    desc: "松本家の愛犬・みかんちゃんは、毎朝の散歩が大好き。家族と一緒に歩く道から見えるのは、いつもと変わらない栖本の景色。",
    vtag: '<iframe src="https://www.youtube.com/embed/bvjArRP6wlo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "02",
    title: "海をきれいに",
    desc: "長年、町の海岸線の清掃活動を行っている末松さん。私たちが口にする海の幸はきれいな海があってこそだと教えてくれる。",
    vtag: '<iframe src="https://www.youtube.com/embed/pu8VU7oUSOg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "03",
    title: "学校の朝",
    desc: "栖本小学校で読み聞かせを行っている「ひまわりの会」の活動が20周年を迎えた。大人と子どもが本でつながり、子どもと本が出合う朝は、気持ちのいい陽光に包まれている。",
    vtag: '<iframe src="https://www.youtube.com/embed/VFr9R2NbFTQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "04",
    title: "栖本に帰る",
    desc: "都会での生活を経て、生まれ故郷の栖本町に戻った渡邉さん。都市生活で求めていたアウトドアなどの遊びは、すべて栖本町でできることを実感する。",
    vtag: '<iframe src="https://www.youtube.com/embed/7RDBZrmfCUc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "05",
    title: "自然に育てる",
    desc: "「牛が気持ちよく暮らせる環境を」と、自ら切り拓いた山で放牧を行う猪原さん。その思いが牛たちに通じているのだろう。やさしくなでる手に安心して寄り添っていた。",
    vtag: '<iframe src="https://www.youtube.com/embed/BbtiowRF3ig" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "06",
    title: "ともだち",
    desc: "奥村くんと坂本くんは、栖本小学校に通う仲のいい同級生。そんな二人は、剣道の時間がやってくると、よきライバルとなる。ともに学び、笑い、切磋琢磨して成長している。",
    vtag: '<iframe src="https://www.youtube.com/embed/PixtiEIc7S4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "07",
    title: "さくらの樹",
    desc: "旧河内小学校の校庭に植えられたさくらの樹。そこには、吉田さんの亡き妻への想いがあった。車の中の奥さんの写真に語りかけ、一緒にその成長を見守っている。",
    vtag: '<iframe src="https://www.youtube.com/embed/HJAXZFVhWb0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
  {
    published: true,
    num: "08",
    title: "栖本の風景",
    desc: "",
    vtag: '<iframe src="https://www.youtube.com/embed/G4Ckv_h2Or0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
  },
];
export default videolist;
