let voicelist = [
  "300年以上も続いている栖本太こ",
  "遊ぶところや、コンビニ、病院がちゃんとある",
  "油すまし",
  "天草大王",
  "安全なところ",
  "あんぜんにあそべる",
  "いい人が多い",
  "家の前の夕暮れ時の海",
  "いきものがいっぱいいる",
  "いげ神様",
  "いげ神様のご利益がハンパないす！",
  "イチゴ",
  "いっぱいはたけとかたんぼがある",
  "いつもにこにこなところ",
  "いなか",
  "今は無いけどアコウの木",
  "うみがきれい",
  "海がきれい",
  "うみがちかい",
  "海がとてもきれいなところ",
  "海と山 両方ある",
  "えびすさまの背中越しの弁財天",
  "円性寺",
  "おいしい魚を食べられる",
  "おいしいやさいがたくさんある",
  "おおきなこえであいさつをするとこ",
  "大河内の棚田の風景",
  "おかあさんたちみなさん料理が上手",
  "お米も美味しい",
  "おじいちゃんとおばあちゃんがやさしい！",
  "おそとにでるとやまがみえる",
  "おはながきれい",
  "おまつりがたのしい",
  "お店がいっぱいでしょくりょうやものがかえるところ",
  "おんせん・コインランドリーなどがあって、べんり！",
  "おんせんきもちいい",
  "温泉最高!!",
  "おんせんにつかると体があたたまる 心もぽかぽか モヤモヤしてても心が晴れる",
  "温泉の泉質がいい",
  "学どう",
  "学校",
  "学校できゅうしょくやべんきょうができるところがよいところだと思う",
  "学校には、お花がいっぱいさいていていいです",
  "かっぱおんせんがすき",
  "かっぱおんせんのとなりのこうえん楽しい",
  "かっぱがいそう",
  "かっぱ街道とかっぱ",
  "かっぱだいこ、すもとだいこのようにずっと続いているもの",
  "かっぱの像がたくさんある",
  "カッパも安心して住める町",
  "かにがいっぱいいる",
  "がねあげ",
  "がねかご",
  "川、海、山があるところ",
  "川、海がキレイ",
  "河内川にはたくさんの生き物が暮らしている",
  "かわがきれい",
  "川がきれい",
  "川がきれい（かっぱがえがおですめるくらい）",
  "川がきれい（ホタルがいるから）",
  "かわち川がよいところ",
  "河内の米",
  "川の水がきれい",
  "川や海がきれい",
  "きがいっぱいある",
  "帰省した時の安心感がハンパないです！落ち着くーって感じ",
  "ぎょうじがたくさんある",
  "行政・生活・医療・産業と過不足が少なくバランスがいい",
  "魚介類が新鮮で美味しい",
  "きれいな川がある",
  "綺麗な星が見えるところ",
  "空気がきれい",
  "空気がきれい",
  "暗い夜空に天の川がはっきり見える！",
  "警察のところの人形",
  "けしきがきれい",
  "景色が最高!!",
  "げんきいっぱいのとこ",
  "元気なところ",
  "剣道が強い",
  "こうえんがきれい",
  "公園が近いからすぐに遊べる",
  "公園とお店と校ていとプール",
  "こうえんにべんちもあります",
  "公共し設が大きくて、広くて、便利",
  "交通事故が少ない",
  "こうばんがあるところ",
  "交流活動や、清掃活動がされている",
  "小ヶ倉観音の参道入口の太陽の光を透かしている緑色のもみじの葉",
  "小ヶ倉観音のぷにっとした不動明王",
  "小ヶ倉の滝",
  "子どもがみんな元気でかわいい",
  "子どもたちが純粋でかわいい",
  "木場野 開拓の歴史と風景",
  "ごみがおちていない",
  "ごみをすてないきれいな町",
  "コンパクトシティ！",
  "さかながいっぱいいる",
  "魚がおいしい",
  "さくらがさく（はる）",
  "三百年の伝統を守ってきた栖本だいこ",
  "事件・事故が少ない",
  "事故が少ない",
  "静か",
  "しぜん",
  "自然",
  "しぜんいっぱい やさしさいっぱい",
  "しぜんがいっぱい",
  "自ぜんがいっぱい",
  "自然がおだやかなところ",
  "しぜんがきれいなところ",
  "自ぜんがたくさんある",
  "しぜんがゆたか",
  "自ぜんがゆたか",
  "自然と生態系が豊か",
  "自然に囲まれた、土地、学校、家",
  "自然に囲まれた美しい町",
  "自然に触れながらのんびり暮らすことができる",
  "自然豊か",
  "自然豊かで生き物がたくさんいる",
  "自まんの河内川があるところ",
  "城跡",
  "じんじゃがいっぱいある",
  "新鮮な食材",
  "すきなところは、ふれあい公園と、かっぱ温せん",
  "栖本温泉がある!!",
  "栖本氏",
  "すもとしょうがっこうのおそとで、みんなといっしょにあそべる",
  "栖本小のちかくにきゅうしょくせんたあがあるからホカホカなごはんがたべられる",
  "すもとだいこ",
  "栖本太鼓踊り",
  "栖本マルシェ",
  "すわ神社",
  "せいざのことをいっぱい教えてくれる",
  "青年団",
  "生物がたくさんいる",
  "そとであそべる",
  "体験ほう富 → 栖本小にしかできない、田植えなどの体験ができる",
  "たいこ",
  "たくさんの人が笑顔であいさつをしている",
  "楽しい行事があるところ",
  "楽しいことがいっぱい",
  "たのしいところ",
  "食べ物がおいしい",
  "民せい委員さんがいてあんしん",
  "田や畑がある",
  "田んぼが多いところ",
  "田んぼがたくさんある",
  "地域の人が仲がいい",
  "ちいきの人がやさしい",
  "地域の人とつながりが強い",
  "地域の人との関係が深い",
  "地域の人との交流が多い",
  "ちいきのみんなとあいさつ",
  "小さな町なのに夏祭りで花火があがる",
  "町民みんな明るい！",
  "ちりめん",
  "つうがくろがあんぜんでいい",
  "つりができる",
  "デコポン",
  "寺子屋体験",
  "寺子屋の学習",
  "寺子屋の体験学習ができる",
  "伝統がある",
  "伝統が受けつがれている",
  "伝統などをずっとうけついでいるところがすばらしい",
  "伝統文化がある",
  "同級生ソフトボール",
  "灯台の灯り",
  "道路やとんねるがあって、本渡に行きやすい",
  "とけいがいっぱいあってじかんがわかる",
  "トマト",
  "友達",
  "友だちがたくさんできる",
  "内海の穏やかさ",
  "長い間続けられてきた栖本だいこがすごい",
  "なかよくできる",
  "夏のホタルがとてもきれい",
  "夏休みにみんなとプールで遊べるところ",
  "にぎやかで楽しい",
  "年代かんけいなく仲がいい",
  "年代をこえて仲が良い",
  "農業がさかんに行われている",
  "ばあちゃんの手作り漬物",
  "はたけがいっぱい",
  "はながいっぱい",
  "花がいっぱい",
  "花がいっぱいさいていて、きれい",
  "花がきれい",
  "花がたくさん、きれいにさいている",
  "浜の獅子舞",
  "春先の野花",
  "久しぶりに帰ったら、声かけてくれる温かい人が多いところ",
  "人があたたかい",
  "人が温かい",
  "人が多い",
  "人が少ない→みんなと仲良し",
  "人がみんないいところ",
  "人がやさしい",
  "人が優しい",
  "人との距離が近い",
  "人と人との繋がりが深く、いつ帰っても、おかえりとみんなが喜んで迎えてくれる",
  "人にかんしゃできるところ",
  "人のつながり",
  "平木場の田の風景",
  "風景がきれい",
  "プールができる",
  "ふれあい公園の大きなあこうの木",
  "文化がある",
  "平和",
  "平和",
  "弁財天の潮に浸かる鳥居",
  "星がきれい",
  "星が綺麗に見える",
  "星空",
  "ホタル",
  "ホタルがたくさんいるということは、川がとてもきれいだ",
  "本当の豊かさがあるように思う",
  "毎日、すごしやすいところ",
  "真夏の栖本トンネル出口から見える倉岳山と雲とか、夏の景色！",
  "周りの人がいい人",
  "周りの人が優しい",
  "みどりがいっぱい",
  "緑がいっぱい 笑顔がいっぱい",
  "緑が多い",
  "緑が多い生き物が多い",
  "みどりがたくさん",
  "みどりたくさん！",
  "みんな明るい",
  "みんなが優しいところ",
  "みんながやさしくしてくれる",
  "みんなとそとであそべる",
  "みんな仲がいい",
  "みんななかよし",
  "みんなのこえがきこえるふれあいこうえん",
  "みんな元きなあいさつをする",
  "みんなやさしい",
  "昔から伝わるとっても大きな神社達",
  "むしがいっぱい",
  "虫がたくさんいる",
  "めだかがいる",
  "やさしい人がいっぱいいる",
  "やさしい人がたくさんいる",
  "山がたくさんあってきれいなところ",
  "山がたくさんある",
  "山がたくさんあるところ",
  "山と海がちかい",
  "山とかに囲まれていてそして虫もたくさんおるしいろんなしぜんがある",
  "山のけしき、やばいほどきれいだ!!",
  "山を這う雲みたいなもの",
  "読み聞かせグループ「ひまわりの会」今年20周年！素敵！",
  "夜が静か",
  "流行にながされない",
  "悪いことをしないのがすもと町のすてきなところ",
];
const shuffle = (array) => {
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
voicelist = shuffle(voicelist);
export default voicelist;
