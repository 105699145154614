<template>
  <header>
    <h1>
      <a href="">
        <img
          src="@/assets/title.svg"
          alt="わがまち再発見プロジェクト わたしたちの栖本町"
        />
      </a>
    </h1>
    <Navigation></Navigation>
  </header>
  <TopSlider />
</template>
<script>
import Navigation from "./Parts-navigation.vue";
import TopSlider from "./Parts-Topslider.vue";
export default {
  data() {
    return {};
  },
  components: {
    Navigation,
    TopSlider,
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.title-enter-active,
.title-leave-active {
  transition: opacity 0.5s ease;
}
.title-enter-from,
.title-leave-to {
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s ease;
  transition-delay: 0.5s;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
header {
  width: 100%;
  padding: 2rem $h-gap 1.375rem $h-gap;
  height: $header-height;
  position: relative;
  top: 0;
  left: 0;
  @include sp {
    height: $header-height-sp;
    padding: 1rem $h-gap 1rem $h-gap;
  }
  h1 {
    width: 13.5rem;
    height: 2.625rem;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
</style>
