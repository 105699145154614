import { createApp } from "vue";
import App from "./App.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VueSmoothScroll from "vue3-smooth-scroll";
import voicelist from "./js/voice-list";
import videolist from "./js/video-list";
// import "@/js/typekit.js";
gsap.registerPlugin(ScrollTrigger);
require("@/assets/scss/main.scss");

const app = createApp(App);
app.use(VueSmoothScroll);
app.config.globalProperties.$voicelist = voicelist;
app.config.globalProperties.$videolist = videolist;
app.config.globalProperties.$voicenum = voicelist.length;
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("a-");
app.mount("#app");
