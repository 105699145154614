<template>
  <LoadingScreen :state="loading"></LoadingScreen>
  <Stalker></Stalker>
  <Header></Header>
  <main>
    <Concept></Concept>
    <Story></Story>
    <VideoList></VideoList>
    <Voices></Voices>
    <Future></Future>
    <transition name="video-modal" appear mode="out-in">
      <Modal
        v-if="modalState"
        @modalclose="modalState = $event.state"
        :targetlist="targetlist"
      ></Modal>
    </transition>
  </main>
  <Footer></Footer>
</template>

<script>
import Concept from "./components/Parts-concept.vue";
import Header from "./components/Parts-header.vue";
import Footer from "./components/Parts-footer.vue";
import Voices from "./components/Parts-voices.vue";
import Story from "./components/Parts-story.vue";
import VideoList from "./components/Parts-videolist.vue";
import Future from "./components/Parts-future.vue";
import Modal from "./components/Parts-modal.vue";
import Stalker from "./components/Parts-MouseStalker.vue";
import LoadingScreen from "@/components/PartsLoading.vue";

export default {
  name: "App",
  data() {
    return {
      modalState: false,
      targetlist: Array,
      loading: true,
    };
  },
  components: {
    Header,
    Concept,
    Voices,
    Story,
    VideoList,
    Future,
    Footer,
    Modal,
    Stalker,
    LoadingScreen,
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  methods: {
    openModal(video) {
      this.modalState = true;
      this.targetlist = video;
    },
  },
  provide() {
    return {
      modalState: this.modalState,
      targetlist: this.targetlist,
      openModal: this.openModal,
    };
  },
};
</script>

<style lang="scss">
body {
  width: 100%;
  height: auto;
}
</style>
