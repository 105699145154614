<template>
  <div id="movie" class="videowrap">
    <!-- class="lazy" -->
    <video
      src="@/assets/web_S.mp4"
      poster="@/assets/poster.jpg"
      class="lazy"
      loop
      muted
      playsinline
    ></video>
    <div class="tx"><h2>SHORT MOVIE</h2></div>
  </div>
  <section id="story">
    <div class="story-tx">
      <h2>人の物語による再発見</h2>
      <h3>
        日々の暮らしをテーマとした
        <br />
        ショートムービーを制作しました
      </h3>
      <p>
        町はそこに暮らす人々によってつくられています。
        <br />
        町の人の日常の中に、この町の姿が見えてくるのではないでしょうか。
      </p>
    </div>
    <!-- <VideoList></VideoList> -->
  </section>
</template>

<script>
// import VideoList from "@/components/Parts-videolist.vue";
export default {
  components: {
    // VideoList,
  },
  created() {
    this.LazyLoading();
  },
  mounted() {},
  methods: {
    LazyLoading() {
      document.addEventListener("DOMContentLoaded", function () {
        let lazyVideo = document.querySelector("video.lazy");
        let lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        lazyVideo.addEventListener("playing", () => {
          console.log("Video is no longer paused");
        });
        let options = {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        };
        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (video) {
              if (video.isIntersecting) {
                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (
                    typeof videoSource.tagName === "string" &&
                    videoSource.tagName === "SOURCE"
                  ) {
                    videoSource.src = videoSource.dataset.src;
                  }
                }

                video.target.load();
                video.target.play();
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);
              }
            });
          }, options);

          lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.videowrap {
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 7/3;
  @include sp {
    aspect-ratio: 7/4;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    @include sp {
    }
    &.active {
    }
  }
  .tx {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: 0.15em;
    font-size: 1.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include sp {
      font-size: 0.875rem;
    }
  }
}
#story {
  padding: $v-gap $h-gap;
  padding-bottom: 0;
  margin-bottom: $v-gap-s;
  @include sp {
    margin-bottom: $v-gap;
  }
  .story-tx {
    margin: 0 auto;
    padding-left: $h-gap-l;
    @include sp {
      padding-left: 0;
    }
    h2 {
      font-weight: 400;
      letter-spacing: 0.1em;
      font-size: 0.975rem;
      margin-bottom: 2rem;
    }

    h3 {
      font-size: 1.75rem;
      margin-bottom: 1.5rem;
      line-height: 1.5;
      letter-spacing: 0.05em;
      position: relative;
      margin-right: clamp(60px, calc(8vw), calc(9.5rem - $h-gap));
      z-index: 1;
      @include sp {
        letter-spacing: -0.05em;
        font-size: 1.375rem;
        margin-right: 0;
      }
    }
    p {
      text-align: left;
      line-height: 2.25rem;
      @include sp {
        font-size: 0.9375rem;
        line-height: 2rem;
        br {
          display: none;
        }
      }
    }
  }
}
</style>
