<template>
  <div id="concept">
    <div class="concept-tx">
      <h2>CONCEPT</h2>
      <div class="inner">
        <h3>あたりまえの中にこそ</h3>
        <div class="paragraph">
          <p>
            慣れ親しんだわがまちで暮らす。
            <br />
            ここにあるのは、珍しくもない、大きな変化もない、“あたりまえ”の日常です。
          </p>
          <p>
            しかし、“あたりまえ”の中にこそ、失ってはいけない“特別”があるのかもしれません。
          </p>
          <p>
            このプロジェクトでは、いろんな人の言葉や視点を借り、“あたりまえ”の中から、
          </p>
          <p>今まで見過ごしていた栖本町の側面を再発見していきます。</p>
          <p>
            それは、この町の本質と向き合うことであり、これからの地域づくりを考える土台になることでしょう。
          </p>
          <p>さぁ、みんなでわがまちを再発見していきましょう！</p>
        </div>
      </div>
    </div>
  </div>
  <div class="concept-gallery">
    <ul class="rows">
      <li class="row1">
        <div class="imgwrap n01">
          <img src="@/assets/img_02.jpg" />
        </div>
      </li>
      <li class="row2">
        <!-- n02 -->
        <div class="imgwrap n02">
          <img src="@/assets/img_01.jpg" />
        </div>
        <div class="imgwrap n03">
          <img src="@/assets/img_09.jpg" />
        </div>
      </li>
      <li class="row3">
        <div class="imgwrap n04">
          <img src="@/assets/img_05.jpg" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#concept {
  padding: $v-gap-l $h-gap;
  padding-bottom: 0;
  margin-bottom: $v-gap-s;
  @include sp {
    margin-bottom: $v-gap;
  }
  .concept-tx {
    margin: 0 auto;
    padding-left: $h-gap-l;
    @include sp {
      padding-left: 0;
    }
    h2 {
      font-weight: 400;
      letter-spacing: 0.1em;
      margin-bottom: $v-gap-s;
    }
    .inner {
      display: flex;
      h3 {
        font-size: 1.75rem;
        line-height: 1;
        position: relative;
        margin-right: clamp(60px, 8vw, calc(9.5rem - $h-gap));
        z-index: 1;
        writing-mode: vertical-lr;
        letter-spacing: 0.25em;
        @include sp {
          font-size: 1.25rem;
        }
      }
      .paragraph {
        p {
          text-align: left;
          line-height: 2.25rem;
          @include sp {
            font-size: 0.9375rem;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
.concept-gallery {
  padding: 0 $h-gap;
  padding-bottom: $v-gap-l;
  overflow: hidden;
  @include tb {
    padding: inherit 0;
  }
  @include sp {
    padding: 0;
    margin-bottom: $v-gap;
  }
  .imgwrap {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
  }
  .rows {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: $v-gap-l;
    @include tb {
      gap: $v-gap-s;
    }
    @include sp {
      gap: 48px;
    }
  }
  .row1 {
    .n01 {
      width: 60%;
      height: auto;
      margin-left: auto;
      margin-right: 0;
      @include sp {
        width: calc(100% - ($h-gap * 2));
      }
    }
  }
  .row2 {
    position: relative;
    @include sp {
      display: flex;
      align-items: flex-end;
    }
    .n02 {
      width: calc(40% - ($h-gap-l * 2));
      aspect-ratio: 2/3;
      position: absolute;
      bottom: $h-gap-l;
      left: $h-gap-l;
      @include sp {
        position: relative;
        left: 0;
        width: calc(50% - 3.5rem);
        bottom: 0;
        margin-bottom: 3rem;
      }
    }
    .n03 {
      width: calc(60% - ($h-gap-l * 2));
      margin-right: $h-gap-l;
      margin-left: auto;
      right: 0;
      @include sp {
        width: 50%;
        aspect-ratio: 3/2;
        margin-right: 0;
      }
    }
  }
  .row3 {
    .n04 {
      width: 60%;
      margin-left: 0;
      margin-right: auto;
      @include sp {
        width: calc(100% - ($h-gap * 2));
      }
    }
  }
  .n01,
  .n03,
  .n04 {
    aspect-ratio: 16/9;
  }
}
</style>
