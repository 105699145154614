<template>
  <footer>
    <div class="footer-inner">
      <div class="footer-title">
        <h2>
          <img
            src="@/assets/title.svg"
            alt="わがまち再発見プロジェクト わたしたちの栖本町"
          />
        </h2>

        <ul>
          <li>
            <a href="http://hp.amakusa-web.jp/a0499/MyHp/Pub/" target="_new">
              栖本町地区振興会HP
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/sumoto_kappa/" target="_new">
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UC-vmBUbIb_aZz5AVhj73QZg"
              target="_new"
            >
              YouTube
            </a>
          </li>
        </ul>
      </div>
    </div>
    <p class="copyright">© 2023 栖本地区振興会</p>
    <button class="return-btn" @click="returnTop()">
      <img src="@/assets/arrow_b.svg" />
      <span>T</span>
      <span>O</span>
      <span>P</span>
    </button>
  </footer>
</template>

<script>
import gsap from "gsap";
export default {
  mounted() {
    this.topbtn();
  },
  methods: {
    returnTop() {
      window.scroll({ top: 0, behavior: "smooth" });
    },
    topbtn() {
      const tgt = ".return-btn";
      gsap.set(tgt, {
        opacity: 0,
        y: "100%",
      });
      gsap.to(tgt, {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "Expo.easeInOut",
        scrollTrigger: {
          trigger: "body",
          start: "top -1000px",
          toggleActions: "play none none reverse",
        },
      });
      gsap.fromTo(
        tgt,
        {
          y: 0,
        },
        {
          x: 0,
          y: -72,
          scrollTrigger: {
            trigger: "footer",
            start: "top bottom-=72px",
            end: "max",
            scrub: true,
            toggleActions: "play none none reverse",
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  height: 12.5rem;
  padding: 0 $h-gap;
  display: flex;
  padding-bottom: $h-gap;
  margin-top: ($v-gap * 1.6);
  position: relative;
  @include sp {
    margin-top: $v-gap-s;
    padding-bottom: 3rem;
  }
}
.footer-inner {
  margin-right: auto;
}
.footer-title {
  h2 {
    display: block;
    width: 14.375rem;
    height: 2.625rem;
    margin-bottom: 2rem;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  ul {
    li {
      font-size: 0.875rem;
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      gap: 0.5rem;
      padding: 0.25rem;
    }
  }
}
.copyright {
  font-size: 0.625rem;
  line-height: 1;
  bottom: 3rem;
  right: $h-gap;
  position: absolute;
}
.return-btn {
  position: fixed;
  z-index: 9990;
  margin: 0 auto;
  bottom: 0;
  right: 0;
  padding: 1rem;
  padding-right: $h-gap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.attatch {
    bottom: 6rem;
  }
  @include tb {
    margin-right: 0;
  }
  img {
    width: auto;
    height: 2.25rem;
    margin-bottom: 1em;
    padding-left: 0.3em;
  }
  span {
    text-align: center;
  }
}
</style>
