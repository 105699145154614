<template>
  <div id="g-ms" class="g-ms">
    <div class="g-ms_i"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.Stalker();
  },
  methods: {
    Stalker() {
      const mouseStalker = document.getElementById("g-ms");
      let msPos = {
        // マウスストーカーの位置
        s: {
          x: document.documentElement.clientWidth / 2,
          y: document.documentElement.clientHeight / 2,
        },
        // マウスポインターの位置
        m: {
          x: document.documentElement.clientWidth / 2,
          y: document.documentElement.clientHeight / 2,
        },
      };

      // マウスストーカーをactiveにする
      if (window.matchMedia("(pointer: fine)").matches) {
        document.addEventListener("mousemove", msActivate);
      }
      function msActivate() {
        mouseStalker.classList.add("g-ms-active");
        document.removeEventListener("mousemove", msActivate);
        // mouseの位置セット
        document.addEventListener("mousemove", function (e) {
          msPos.m.x = e.clientX;
          msPos.m.y = e.clientY;
        });
        // アニメーション開始
        requestAnimationFrame(msPosUpdate);
      }

      // マウスストーカーを動かす関数
      function msPosUpdate() {
        msPos.s.x += (msPos.m.x - msPos.s.x) * 0.1;
        msPos.s.y += (msPos.m.y - msPos.s.y) * 0.1;
        const x = Math.round(msPos.s.x * 10) / 10;
        const y = Math.round(msPos.s.y * 10) / 10;
        mouseStalker.style.transform =
          `translate3d(` + x + "px," + y + "px, 0)";
        requestAnimationFrame(msPosUpdate);
      }

      // hover時にclass追加
      const stalkerLinkObj = document.querySelectorAll(
        "a, button, .msl, *[pointer]"
      );
      for (let i = 0; i < stalkerLinkObj.length; i++) {
        stalkerLinkObj[i].addEventListener("mouseover", function () {
          mouseStalker.classList.add("g-ms-hover");
        });
        stalkerLinkObj[i].addEventListener("mouseout", function () {
          mouseStalker.classList.remove("g-ms-hover");
        });
      }
    },
  },
};
</script>
<style lang="scss">
.g-ms {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transform: translate3d(50vw, 50vh, 0);
  pointer-events: none;
  z-index: 9999;
  @include tb {
    display: none;
  }
}
.g-ms_i {
  width: 1.25rem;
  height: 1.25rem;
  margin: -0.625px 0 0 -0.625px;
  border-radius: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  transition: all 0.3s $easing;
  opacity: 0;
}

/* アクティブ時 */
.g-ms.g-ms-active .g-ms_i {
  opacity: 1;
}

/* マウスホバー時 */
.g-ms.g-ms-hover .g-ms_i {
  mix-blend-mode: soft-light;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.25);
  width: 7.5rem;
  height: 7.5rem;
  margin: -3.75rem 0 0 -3.75rem;
  transition: all 0.3s $easing;
}
</style>
