<template>
  <div id="future">
    <div class="concept-tx">
      <div class="inner">
        <h2>これからの栖本まちづくり</h2>
        <div class="paragraph">
          <p>
            町の人は言います。
            <br />
            「栖本ってなんがあるかい？」
            <br />
            「住んどるけど、よーしらんなぁ」
          </p>
          <p>
            “あたりまえ”のことだから、気づかない。
            <br />
            しかし、私たちが“あたりまえ”と感じている中にこそ栖本の魅力があるのではないか。そんな思いから、「わがまち栖本町を再発見しよう」と、このプロジェクトに取り組むこととしました。
          </p>
          <p>
            栖本小学校・中学校の子どもたちをはじめ、町の人に「栖本のよかとこ・好きなとこ」を聞いてみると、伝統ある栖本太鼓踊りへの誇りや、ホタルが生息する河内川、穏やかな海、キレイな星空などの自然、元気でかわいい子どもたちの姿、そして、人のやさしさやあたたかさについて触れる声が多く寄せられました。
          </p>
          <p>
            そうした声をもとに、栖本町に暮らす人々のありのままを映す、７つの短編映像を制作しました。
            そこには、暮らしに溶け込む風景、営み、思いなど、日々、目にしながら見過ごしてきた、あるいは、知らなかったことが映し出されています。
          </p>
          <p>
            今、私たちが目にし、触れているたくさんのものごとや人々の営みは、何気ない日々の積み重ねの中から生まれ、先人から脈々とつながってきたものでもあります。
            だからこそ、今を生きる私たちも、未来を生きる子どもたちにこの栖本町の財産を残していかなければならないのではないでしょうか。
          </p>
          <p>
            「わがまち再発見」をきっかけとして、かけがえのない栖本町の姿に気づき、一人ひとりがそれらを大切に思い、守ること。これが、栖本のまちづくりの土台となっていくように思います。
          </p>
        </div>
      </div>
    </div>
    <div class="future-gallery row2">
      <div class="imgwrap n01">
        <img src="@/assets/img_07.jpg" />
      </div>
      <div class="imgwrap n02">
        <img src="@/assets/img_08.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#future {
  .concept-tx {
    margin: 0 auto;
    position: relative;
    @include sp {
    }
    .inner {
      display: flex;
      margin: 0 auto;
      gap: calc(2 * $h-gap-l);
      width: 100%;
      @include tb {
        padding: 0 $h-gap;
      }
      @include sp {
        gap: calc(2 * $h-gap);
      }
      h2 {
        display: flex;
        align-items: flex-end;
        width: 40%;
        font-size: 1.75rem;
        line-height: 1;
        position: relative;
        z-index: 1;
        writing-mode: vertical-lr;
        letter-spacing: 0.25em;
        padding-left: calc($h-gap + $h-gap-l);
        @include tb {
          width: 2em;
          flex-grow: 0;
          padding-left: 0;
        }
        @include sp {
          width: 1em;
          flex-grow: 0;
          font-size: 1.25rem;
          padding-left: 0;
        }
      }
      .paragraph {
        flex-shrink: 0;
        flex-grow: 0;
        width: 58%;
        padding-right: calc($h-gap + $h-gap-l);
        position: relative;
        @include tb {
          padding-right: 0;
          flex-shrink: 1;
          flex-grow: 1;
        }
        @include sp {
          padding-right: 0;
          flex-shrink: 1;
          flex-grow: 1;
        }

        p {
          max-width: 34.375rem;
          font-size: 0.875rem;
          text-align: left;
          line-height: 2.5;
          margin-bottom: 2.5em;
          &:last-child {
            margin-bottom: 0;
          }
          @include sp {
            max-width: none;
            line-height: 2;
            margin-bottom: 2em;
          }
        }
      }
    }
  }
}
.future-gallery {
  overflow: hidden;
  padding: 0;
  &.row2 {
    padding-top: $v-gap;
    position: relative;
    overflow: visible;
    @include sp {
      padding-top: $v-gap-s;
    }
    .n01 {
      width: 35%;
      position: absolute;
      top: -13%;
      aspect-ratio: 5/3;
      @include tb {
        position: relative;
        width: 60%;
        margin-bottom: 2.5rem;
      }
      @include sp {
        position: relative;
        width: 90%;
      }
    }
    .n02 {
      margin-left: auto;
      width: 50%;
      aspect-ratio: 16/9;
      img {
        object-position: right;
      }
      @include tb {
        width: 50%;
        aspect-ratio: 6/5;
      }
      @include sp {
        width: 66%;
        aspect-ratio: 6/5;
      }
    }
  }
  .imgwrap {
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;

      background-color: #eee;
    }
  }
}
</style>
