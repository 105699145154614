<template>
  <section id="voices">
    <h2>みんなの声で再発見</h2>
    <h3>
      小中学生を中心に、町のみなさんが
      <br />
      栖本のよかとこ・好きなとこをあげてくれました。
    </h3>
    <div id="voices-swiper">
      <swiper
        class="voiceswiper"
        :grabCursor="true"
        :speed="1000"
        :rewind="true"
        :scrollbar="{
          el: '.voice-scrollbar',
          draggable: true,
          dragClass: 'drag',
        }"
        :modules="modules"
        :breakpoints="breakpoints"
        @swiper="onSwiper"
      >
        <swiper-slide v-for="(page, index) in voices" :key="index">
          <ul>
            <li v-for="(item, n) in page" :key="n">
              <p>{{ item }}</p>
            </li>
          </ul>
        </swiper-slide>
        <div class="swipeGuide">
          <div class="icon">
            <img
              src="@/assets/swipe.svg"
              alt="スワイプで横にスクロールできます"
            />
          </div>
        </div>
      </swiper>
    </div>
    <div class="swiper-controll">
      <div class="voice-scrollbar">
        <div class="drag"></div>
      </div>
      <div class="voice-button">
        <button class="prev" @click="SlideChange('prev')">Back</button>
        <button class="next" @click="SlideChange('next')">Next</button>
      </div>
    </div>
  </section>
  <div class="future-gallery row1">
    <div class="imgwrap n01"><img src="@/assets/img_06.jpg" /></div>
    <div class="imgwrap n02"><img src="@/assets/img_03.jpg" /></div>
  </div>
</template>

<script>
import gsap from "gsap";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

// import "swiper/css/scrollbar";
import "swiper/css/pagination";

import { Autoplay, Scrollbar } from "swiper";
export default {
  created() {
    this.sliceList();
  },
  mounted() {
    this.scrollAnimation();
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      voices: null,
      breakpoints: {
        380: {
          slidesPerView: 1.2,
        },
        640: {
          slidesPerView: 1.5,
        },
        960: {
          slidesPerView: 2.5,
        },
      },
    };
  },
  setup() {
    return {
      modules: [Autoplay, Scrollbar],
    };
  },
  methods: {
    scrollAnimation() {
      this.swipeGuide();
    },
    swipeGuide() {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#voices .swiper",
          start: "top 80%",
          toggleActions: "play pause resume reset",
        },
      });
      tl.fromTo(
        ".swipeGuide",
        { opacity: 0 },
        { opacity: 1, delay: 0.5, duration: 0.5 }
      );
      tl.to(
        ".swipeGuide .icon img",
        {
          x: "+=10",
          yoyo: true,
          yoyoEase: true,
          repeat: 1,
          ease: "Power4.in",
          duration: 0.75,
        },
        0.5
      );
      tl.to(".swipeGuide", {
        opacity: 0,
        delay: 0.25,
        duration: 0.5,
      });
    },
    sliceList() {
      const length = Math.ceil(this.$voicelist.length / 16);
      let newArr = [];
      for (let i = 0; i < length; i++) {
        let j = i * length;
        let p = this.$voicelist.slice(j, j + length);
        newArr.push(p);
      }
      this.voices = newArr;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    SlideChange(direction) {
      if (direction == "next") {
        this.swiper.slideNext();
      } else {
        this.swiper.slidePrev();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swipeGuide {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  display: none;
  @include tb {
    display: flex;
  }
  .icon {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    img {
      padding-right: 0.3125rem;
    }
  }
}
.swiper {
  overflow: visible;
}
.swiper-controll {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: $h-gap-l;
  @include sp {
    margin-top: 1rem;
  }
  .voice-scrollbar {
    position: relative;
    left: 0;
    bottom: 0px;
    height: 1.25rem;
    width: 100%;
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background: rgba(0, 0, 0, 0.25);
      top: calc(0.625rem / 2);
    }
    .drag {
      height: 1.25rem;
      position: absolute;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }

      left: 0;
      top: 0px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $color-text;
        top: calc(0.625rem / 2);
      }
    }
  }
  .voice-button {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    &::after {
      content: "";
      background-color: $color-text;
      position: absolute;
      height: 110%;
      width: 1px;
      left: 50%;
      top: -5%;
    }
    button {
      position: relative;
      padding: 0 3em;
      @include sp {
        font-size: 0.875rem;
        padding: 0 3em;
      }
      &::before,
      &::after {
        content: "";
        background-color: $color-text;
        position: absolute;
        height: 55%;
        width: 1px;
      }
      &::before {
        bottom: 50%;
        transform-origin: center bottom;
        transform: rotate(45deg);
      }

      &::after {
        top: 50%;
        transform-origin: center top;
        transform: rotate(-45deg);
      }
      &.prev {
        &::before {
          left: 0;
          transform: rotate(45deg);
        }

        &::after {
          left: 0;
          transform: rotate(-45deg);
        }
      }
      &.next {
        &::before {
          right: 0;
          transform: rotate(-45deg);
        }

        &::after {
          right: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
.swiper-slide {
  font-size: 0.975rem;
  ul {
    padding-right: 3rem;
  }
  li {
    margin-bottom: 0.75em;
    p {
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.25;
      &::before {
        content: "#";
        margin-right: 0.5em;
      }
      @include sp {
        font-size: 0.875rem;
      }
    }
  }
}
#voices {
  padding: 12.5rem calc($h-gap-l + $h-gap);
  overflow: hidden;
  @include sp {
    padding: $v-gap $h-gap;
  }
  h2 {
    font-weight: 400;
    font-size: 0.975rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-size: 1.75rem;
    margin-bottom: 4.5rem;
    line-height: 1.5;
    @include sp {
      letter-spacing: 0;
      font-size: 1.375rem;
      margin-right: 0;
      margin-bottom: 2rem;
      br {
        display: none;
      }
    }
  }
}
.future-gallery {
  overflow: hidden;
  padding: 0;
  .imgwrap {
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &.row1 {
    display: flex;
    gap: calc(2 * $h-gap-l);
    padding-bottom: 12.5rem;
    @include sp {
      flex-direction: column-reverse;
      padding-bottom: $v-gap-s;
      gap: 2.5rem;
    }
    .n01 {
      width: 60%;
      @include tb {
        aspect-ratio: 3/2;
      }
      @include sp {
        aspect-ratio: 1/1;
      }
    }
    .n02 {
      width: 58%;
      flex-shrink: 0;
      flex-grow: 0;
      aspect-ratio: 2/1;
      @include tb {
        aspect-ratio: 3/2;
        margin-left: auto;
      }
      @include sp {
        aspect-ratio: 5/3;
        width: calc(100% - 40px);
        margin-left: auto;
      }
    }
  }
}
</style>
