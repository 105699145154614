<template>
  <div
    id="main-nav-overlay"
    :class="{ active: navstate }"
    @click="navstate = !navstate"
  ></div>
  <div
    pointer
    id="main-nav-btn"
    @click="navstate = !navstate"
    :class="{ active: navstate }"
  >
    Menu
  </div>
  <div
    pointer
    id="main-nav-close"
    @click="navstate = !navstate"
    :class="{ active: navstate }"
  >
    <div class="btn">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <nav id="main-nav" :class="{ active: navstate }" style="display: none">
    <ul class="nav-top">
      <li>
        <a @click="(navstate = !navstate), returnTop()">
          <h3>
            TOP
            <span>トップ</span>
          </h3>
        </a>
      </li>
      <li>
        <a href="#movie" @click="navstate = !navstate">
          <h3>
            MOVIE
            <span>ムービー</span>
          </h3>
        </a>
        <ul class="nav-movie">
          <li v-for="(video, index) in $videolist" :key="index">
            <a :href="'#' + video.title" @click="navstate = !navstate">
              <span class="num">{{ video.num }}</span>
              <h4 class="title">{{ video.title }}</h4>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a href="#voices" @click="navstate = !navstate">
          <h3>
            VOICE
            <span>みんなの声</span>
          </h3>
        </a>
      </li>
      <li>
        <a href="#future" @click="navstate = !navstate">
          <h3>
            FUTURE
            <span>これからの栖本まちづくり</span>
          </h3>
        </a>
      </li>
    </ul>
    <ul class="nav-bottom">
      <li>
        <a href="http://hp.amakusa-web.jp/a0499/MyHp/Pub/" target="_new">
          栖本町地区振興会HP
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/sumoto_kappa/" target="_new">
          Instagram
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UC-vmBUbIb_aZz5AVhj73QZg"
          target="_new"
        >
          YouTube
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      navstate: false,
    };
  },
  components: {},
  mounted() {
    document.querySelector("#main-nav").style.display = "block";
  },
  methods: {
    returnTop() {
      window.scroll({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
<style lang="scss" scoped>
#main-nav-btn,
#main-nav-close {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  margin-right: $h-gap;
  cursor: pointer;

  transition: 0.2s;
}
#main-nav-btn {
  font-size: 1.08rem;
  padding-left: 1.2em;
  margin-top: 3.1875rem;
  @include sp {
    margin-top: 1.75rem;
  }
  &.active {
    opacity: 0;
  }
  &::before {
    content: "";
    display: block;
    background-color: $color-green;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 0.2em;
  }
}
#main-nav-close {
  $closebtn-size: 1.875rem;
  opacity: 0;
  overflow: hidden;
  margin-top: 2.5rem;
  @include sp {
    margin-top: 1.375rem;
  }
  &.active {
    opacity: 1;
  }
  .btn {
    transform: rotate(-45deg);
    position: relative;
    width: $closebtn-size;
    height: $closebtn-size;
    span {
      display: block;
      position: absolute;
      top: calc($closebtn-size / 2 - 1px);
      width: 1px;
      height: 1px;
      background-color: $color-text;
      transition: 0.2s;

      &:nth-child(1) {
        width: $closebtn-size;
        transform: scaleX(1);
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        left: calc(50% - 1px);
        transform-origin: left top;
        height: calc($closebtn-size / 2);
      }
      &:nth-child(3) {
        top: calc(50% + 4px);
        left: calc(50% - 1px);
        transform-origin: left;
        height: calc($closebtn-size / 2 - 4px);
      }
    }
  }
}
#main-nav-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 9998;
  backdrop-filter: blur(3px) saturate(1.5);
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 1s $easing, left 0s 1s;
  pointer-events: none;

  &.active {
    opacity: 1;
    left: 0;
    transition: opacity 1s $easing;
    pointer-events: auto;
  }
  .filter {
    width: 100%;
    height: 100%;

    will-change: backdrop-filter;
    &.active {
      opacity: 1;
    }
  }
}

#main-nav {
  transition: 0.5s $easing;
  position: fixed;
  z-index: 9998;
  left: 100%;
  top: 0;
  height: 100vh;
  height: 100lvh;
  width: 35rem;
  background-color: white;
  padding: $header-height calc($h-gap * 3) $header-height calc($h-gap * 2);
  overflow: hidden;
  @include sp {
    width: 100%;
    padding: $header-height-sp $h-gap $header-height-sp $h-gap;
  }
  &.active {
    transform: translateX(-100%);
  }

  .nav-top {
    display: grid;
    grid-gap: 1.125rem;
    h3 {
      font-size: 1.25rem;
      line-height: 1;
      letter-spacing: 0.125em;
      span {
        font-size: 0.75rem;
        line-height: 0.5;
        letter-spacing: 0;
        &::before {
          content: "\A";
          white-space: pre-wrap;
        }
      }
    }
    li {
      display: flex;
      gap: calc($h-gap * 2);
      .nav-movie {
        display: grid;
        grid-gap: 1.125rem;
        padding-right: $h-gap;
        @include sp {
          padding-right: 0;
        }
        li {
          a {
            display: inline-flex;
            .num {
              font-size: 0.875rem;
              font-style: italic;
              margin-right: 1rem;
            }
            h4 {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }
  .nav-bottom {
    border-top: 1px solid $color-text;
    margin-top: 1.125rem;
    padding-top: 1.125rem;
    li {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  }
}
</style>
