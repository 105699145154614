<template>
  <transition name="opacity">
    <div id="loading" v-if="state">
      <p>LOADING</p>
      <div class="loading-ripple">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  components: {},
  props: ["state"],
};
</script>

<style lang="scss" scoped>
#loading {
  font-family: serif;
  z-index: 10000;
  padding: $h-gap;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  will-change: backdrop-filter;
  p {
    margin-top: 1em;
    font-size: 0.875rem;
  }
}
.opacity-enter {
  opacity: 0;
}
.opacity-enter-active {
  transition: opacity 0.25s;
}
.opacity-enter-to {
  opacity: 1;
}
.opacity-leave {
  opacity: 1;
}
.opacity-leave-active {
  transition: opacity 0.5s;
}
.opacity-leave-to {
  opacity: 0;
}
</style>
