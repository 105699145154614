<template>
  <div id="top-slider-wrap">
    <div id="scroll">
      <p>SCROLL</p>
      <img src="@/assets/arrow_w.svg" alt="scroll" />
    </div>
    <h2 class="maintx">SUMOTO</h2>
    <swiper
      ref="topslider"
      :effect="'fade'"
      :centeredSlides="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :speed="0"
      :modules="modules"
      class="mySwiper"
      @swiper="onSwiper"
      @slideChange="getRealIndex"
    >
      <swiper-slide v-for="(video, index) in $videolist" :key="index">
        <div class="image">
          <img
            class="pc"
            :src="require('@/assets/slide/top_pc_' + video.num + '.jpg')"
            :alt="video.title"
          />
          <img
            class="sp"
            :src="require('@/assets/slide/top_sp_' + video.num + '.jpg')"
            :alt="video.title"
          />
        </div>
        <div class="tx">
          <h4>Short Movie</h4>
          <div class="title">
            <p class="num">{{ video.num }}</p>
            <img class="line" src="@/assets/line-w.svg" />
            <h3>{{ video.title }}</h3>
          </div>
          <button class="watch" @click="openModal(video)">
            <img class="icon" src="@/assets/PlayIcon.svg" />
            Watch
          </button>
        </div>
      </swiper-slide>
    </swiper>
    <div class="control">
      <button class="prev" @click="SlideChange('prev')">PREV</button>
      <div class="indicator">
        <p>
          {{ ("00" + (IndexArr[realIndex] - 1)).slice(-2) }}
        </p>
        <p>{{ ("00" + (maxIndex - 1)).slice(-2) }}</p>
      </div>
      <button class="next" @click="SlideChange('next')">NEXT</button>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
// import required modules
import { EffectFade, Autoplay } from "swiper";
export default {
  data() {
    return {
      swiper: null,
      realIndex: 0,
      realIndexURL: 0,
      maxIndex: 0,
      IndexArr: [],
    };
  },
  inject: ["openModal"],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectFade, Autoplay],
    };
  },
  mounted() {},
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
      this.getMaxIndex();
      this.getRealIndex();
    },
    SlideChange(direction) {
      if (direction == "next") {
        if (this.swiper.realIndex >= this.swiper.slides.length - 1) {
          this.swiper.slideTo(0);
        } else {
          this.swiper.slideNext();
        }
      } else {
        if (this.swiper.realIndex <= 0) {
          this.swiper.slideTo(this.swiper.slides.length - 1);
        } else {
          this.swiper.slidePrev();
        }
      }
    },
    getMaxIndex() {
      let num = this.swiper.slides.length;
      this.maxIndex = ("00" + num).slice(-2);
      for (let i = 0; i < num; i++) {
        let item = ("00" + (i + 1)).slice(-2);
        this.IndexArr.push(item);
      }
    },
    getRealIndex() {
      let num = this.swiper.realIndex;
      this.realIndex = num;
      let activeslide = document.querySelector(
        ".swiper-slide-visible .image img"
      );
      this.realIndexURL = activeslide.getAttribute("src");
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
#scroll {
  position: absolute;
  z-index: 999;
  left: $h-gap;
  bottom: 2rem;
  font-size: 0.75rem;
  @include sp {
    display: none;
  }
  p {
    line-height: 1;
    color: white;
    writing-mode: vertical-lr;
    font-style: italic;
    letter-spacing: 0.1em;
  }
  img {
    height: 1.5rem;
    margin-top: 1em;
    padding-left: 0.25em;
  }
}
#top-slider-wrap {
  position: relative;
  .maintx {
    font-size: 6.5rem;
    position: absolute;
    bottom: -0.25em;
    right: $h-gap;
    z-index: 999;
    color: $color-green;
    letter-spacing: 0.15em;
    line-height: 0.65;
    @include tb {
      font-size: clamp(4rem, 10vw, 6.5rem);
      letter-spacing: 0.5vw;
    }
    @include sp {
      font-size: 4rem;
      letter-spacing: 0.05em;
      right: 0;
    }
  }
}
.control {
  position: absolute;
  bottom: 0;
  margin-left: calc($h-gap-l + $h-gap);
  margin-bottom: 2rem;
  color: white;
  display: flex;
  z-index: 1;
  @include sp {
    margin-left: $h-gap;
    display: none;
  }
  button {
    width: 1em;
    overflow: hidden;
    position: relative;
    color: transparent;
    padding: 0 1.5em;
    &::before,
    &::after {
      content: "";
      background-color: white;
      position: absolute;
      height: 55%;
      width: 1px;
    }
    &::before {
      bottom: 50%;
      transform-origin: center bottom;
      transform: rotate(45deg);
    }
    &::after {
      top: 50%;
      transform-origin: center top;
      transform: rotate(-45deg);
    }
    &.prev {
      &::before {
        left: 0;
        transform: rotate(45deg);
      }
      &::after {
        left: 0;
        transform: rotate(-45deg);
      }
    }
    &.next {
      &::before {
        right: 0;
        transform: rotate(-45deg);
      }
      &::after {
        right: 0;
        transform: rotate(45deg);
      }
    }
  }
  .indicator {
    display: flex;
    font-style: italic;
    p {
      letter-spacing: 0.1em;
      line-height: 1.5;
      position: relative;
      &:nth-child(2) {
        margin-left: 1em;
        padding-left: 1em;
        &::before {
          content: "";
          height: 1.5em;
          width: 1px;
          display: block;
          background-color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
.swiper {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - $header-height - 4rem);
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  @include sp {
    height: calc(100vh - $header-height-sp - 160px);
    height: calc(100lvh - $header-height-sp - 160px);
  }
}
.swiper-slide {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  @include sp {
  }
  img.pc,
  img.sp {
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    filter: blur(10px) brightness(2);
    transition: transform 2s cubic-bezier(0.64, 0, 0.78, 0), filter 2s,
      opacity 1s;
  }
  img.pc {
    @include sp {
      display: none;
    }
  }
  img.sp {
    display: none;
    @include sp {
      display: block;
    }
  }
  &.swiper-slide-active {
    .tx {
      opacity: 1;
    }
  }
  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .tx {
    position: absolute;
    left: 0;
    margin-left: calc($h-gap-l + $h-gap);
    bottom: 6.25rem;
    color: white;
    opacity: 0;
    transition: 1.5s;
    @include sp {
      margin-left: $h-gap;
      bottom: 64px;
    }
    h4 {
      font-style: italic;
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
      @include sp {
        margin-bottom: 1rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 1.625rem;
      .num {
        font-style: italic;
      }
      .line {
        height: 4.5rem;
        @include sp {
          height: 4rem;
          margin-left: -0.45em;
        }
      }
      h3 {
        white-space: pre;
        letter-spacing: 0.05em;
      }
    }
    .watch {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }
      img {
        filter: invert(1);
        width: 1.375rem;
        margin-right: 0.5em;
      }
    }
  }
  img.pc,
  img.sp {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  img.pc {
    @include sp {
      display: none;
    }
  }
  img.sp {
    display: none;
    @include sp {
      display: block;
    }
  }
}
.swiper-slide-active {
  img.pc,
  img.sp {
    opacity: 1;
    filter: blur(0px) brightness(0.75);
    transform: scale(1.05);
    transition: transform 5s ease-out, filter 2s, opacity 1s;
  }
}
</style>
