<template>
  <div class="modal" id="modal">
    <div class="overlay" @click="closemodal"></div>
    <div class="modalcontents" id="modaltarget">
      <div class="modalclosebtn" @click="closemodal">
        <div class="btn">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="modalcontents-inner">
        <div class="modalcontents-mainimg">
          <div
            class="video-wrap"
            v-if="targetlist.vtag"
            v-html="targetlist.vtag"
          ></div>
        </div>
        <div class="modalcontents-tx">
          <div class="title">
            <p class="num">{{ targetlist.num }}</p>
            <h3>{{ targetlist.title }}</h3>
          </div>
          <p class="desc">{{ targetlist.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { lock, unlock } from "tua-body-scroll-lock";

export default {
  props: ["targetlist"],

  emits: ["modalclose"],
  data() {
    return {};
  },
  mounted() {
    let targetElement = document.querySelector("#modaltarget");
    lock(targetElement);
  },
  methods: {
    closemodal() {
      let targetElement = document.querySelector("#modaltarget");
      unlock(targetElement);
      this.$emit("modalclose", false);
    },
  },
};
</script>
<style lang="scss">
.video-wrap {
  background-color: black;
  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
}
</style>
<style lang="scss" scoped>
// style
.modal {
  padding: $h-gap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  @include tb {
    padding: $h-gap;
  }
  @include sp {
    padding: $h-gap 0;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(3px) saturate(1.5);
    will-change: backdrop-filter;
  }
  .modalcontents {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 57.5rem;
    @include tb {
      max-width: 640px;
    }
    .modalcontents-inner {
      overscroll-behavior: contain;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include tb {
      }

      .modalcontents-mainimg {
        flex-shrink: 0;
        overflow: hidden;
        aspect-ratio: 16/9;
        align-self: flex-start;
        @include tb {
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .modalcontents-tx {
        color: white;
        width: 100%;
        text-align: left;
        display: flex;
        margin-top: 1rem;
        @include tb {
          display: block;
        }
        @include sp {
          padding: 0 $h-gap;
        }
        .title {
          display: flex;
          align-items: center;
        }
        .num {
          font-style: italic;
          letter-spacing: 0.1em;
          position: relative;
          margin: 0;
          width: 4em;
          height: 3em;
          font-size: 1.5rem;
          line-height: 3em;
          flex-shrink: 0;
          background-image: url(@/assets/line-w.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right center;
        }
        h3 {
          font-size: 1.5rem;
          span {
            display: block;
            font-size: 1.5rem;
            margin-right: auto;
          }
        }
        .desc {
          font-size: 0.75rem;
          max-width: 26.25rem;
          margin-left: auto;
          padding-left: 2em;
          @include tb {
            max-width: none;
            padding-left: 6rem;
            margin-top: -1em;
          }
        }
      }
    }
  }
  .modalclosebtn {
    font-size: 1.875rem;
    overflow: hidden;
    position: absolute;
    left: 100%;
    bottom: 100%;
    z-index: 999;
    padding: 0.5rem;
    @include tb {
      left: auto;
      right: 0;
    }
    .btn {
      transform: rotate(-45deg);
      position: relative;
      width: 1em;
      height: 1em;
      span {
        display: block;
        position: absolute;
        top: calc(1em / 2 - 1px);
        width: 1px;
        height: 1px;
        background-color: white;
        transition: 0.2s;

        &:nth-child(1) {
          width: 1em;
          transform: scaleX(1);
          left: 0;
        }
        &:nth-child(2) {
          top: 0;
          left: calc(50% - 1px);
          transform-origin: left top;
          height: calc(1em / 2);
        }
        &:nth-child(3) {
          top: calc(50% + 4px);
          left: calc(50% - 1px);
          transform-origin: left;
          height: calc(1em / 2 - 4px);
        }
      }
    }
  }
}

//
.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.25s ease-in-out;
}
.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.25s ease-in-out;
}
</style>
