<template>
  <div id="video-list">
    <div class="video-grid">
      <div class="container" v-for="(video, index) in limitCount" :key="index">
        <div class="thumb" :id="video.title" @click="openModal(video)" pointer>
          <img
            :src="require('@/assets/thumb/movie_thum_' + video.num + '.jpg')"
            :alt="video.title"
          />
        </div>
        <div class="tx">
          <p class="num">{{ video.num }}</p>
          <div class="info">
            <h3>
              {{ video.title }}
            </h3>
            <p class="desc">{{ video.desc }}</p>
            <button class="play" @click="openModal(video)">
              <img src="@/assets/PlayIcon.svg" />
              PLAY
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="video-large" :id="$videolist[$videolist.length - 1].title">
    <div class="imgwrap">
      <img
        :src="
          require('@/assets/thumb/movie_thum_' +
            $videolist[$videolist.length - 1].num +
            '.jpg')
        "
        :alt="$videolist[$videolist.length - 1].title"
      />
    </div>
    <div class="tx">
      <p class="num">{{ $videolist[$videolist.length - 1].num }}</p>
      <div
        class="info"
        pointer
        @click="openModal($videolist[$videolist.length - 1])"
      >
        <h3>
          SCENERY OF
          <br />
          <span>SUMOTO</span>
        </h3>
        <h4>
          {{ $videolist[$videolist.length - 1].title }}
        </h4>
        <button
          class="play"
          @click="openModal($videolist[$videolist.length - 1])"
        >
          <img src="@/assets/PlayIcon.svg" />
          PLAY
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  inject: ["openModal"],
  components: {
    // Modal,
  },
  mounted() {},
  computed: {
    limitCount() {
      let length = this.$videolist.length - 1;
      let list;
      list = Object.values(this.$videolist).slice(0, length);
      return list;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#video-list {
  padding: 0 $h-gap;
  @include sp {
    padding: 0;
  }
}
.video-grid {
  display: grid;
  grid-gap: $v-gap-s;
  padding: 0 $h-gap-l;
  margin-bottom: $v-gap-s;
  @include sp {
    padding: 0;
  }
  .container {
    border-top: 1px solid white;
    cursor: pointer;
    display: flex;
    gap: $h-gap-l;
    transition: 0.2s;
    pointer-events: none;
    @include tb {
      display: block;
      gap: 0.5rem;
    }
    @include sp {
      display: block;
    }
    &:hover {
      .thumb::after {
        opacity: 1;
        visibility: visible;
      }
    }
    .thumb {
      flex-basis: 60%;
      flex-grow: 0;
      overflow: hidden;
      aspect-ratio: 16/9;
      align-self: start;
      position: relative;
      pointer-events: auto;
      &::after {
        content: "";
        display: block;
        background-color: rgba(0, 0, 0, 0.3);
        background-image: url(@/assets/PlayIcon-w.svg);
        background-size: 3rem;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        visibility: hidden;
        pointer-events: none;
        transition: 0.5s $easing;
        @include tb {
          display: none;
        }
      }
      &:hover {
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-color: #eee;
      }
    }
    .tx {
      flex-basis: 60%;
      flex-grow: 1;
      display: flex;
      gap: 2.5rem;
      align-items: left;
      padding-top: 2.5rem;
      @include sp {
        gap: 1rem;
        padding: 40px 24px 0 50px;
      }

      .num {
        font-style: italic;
        letter-spacing: 0.1em;
        position: relative;
        padding: 0;
        margin: 0;
        width: 5.625rem;
        height: 5.625rem;
        font-size: 1.5rem;
        line-height: 1.5;
        flex-shrink: 0;
        background-image: url(@/assets/line.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @include sp {
          font-size: 1.25em;
          width: 4.375rem;
          height: 4.375rem;
        }
      }
      .info {
        h3 {
          font-size: 1.5rem;
          align-items: center;
          margin-top: 0.125em;
          margin-bottom: 1em;
          @include sp {
            font-size: 1.25rem;
          }
        }
        .desc {
          font-size: 0.875rem;
          line-height: 2;
        }
        .play {
          font-size: 0.875rem;
          margin-top: 1em;
          display: flex;
          align-items: center;
          letter-spacing: 0.1em;
          pointer-events: auto;
          padding: 1em;
          margin-left: -1em;

          img {
            width: 1.5rem;
            height: auto;
            margin-right: 0.75em;
          }
          &:hover {
          }
        }
      }
    }
  }
}
.video-large {
  position: relative;

  .imgwrap {
    width: 100%;
    @include tb {
      aspect-ratio: 3/2;
    }
    @include sp {
      aspect-ratio: 5/7;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 35%;
    }
  }
  .tx {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    padding: 6.25rem 0 0 calc($h-gap-l + $h-gap);
    color: white;
    @include tb {
      padding-top: 64px;
    }
    @include sp {
      padding: calc($h-gap * 2) $h-gap;
    }
    .num {
      font-style: italic;
      letter-spacing: 0.1em;
      position: relative;
      padding: 0;
      margin: 0;
      width: 3.75em;
      height: 3.75em;
      font-size: 1.5rem;
      line-height: 1.5;
      flex-shrink: 0;
      background-image: url(@/assets/line-w.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @include sp {
        font-size: 1.25em;
        width: 4.375rem;
        height: 4.375rem;
      }
    }
    .info {
      cursor: pointer;
      margin-top: -2.5rem;
      margin-left: 3.75rem;
      @include sp {
        margin-left: 3.5rem;
      }
      h3 {
        font-size: 1.3125rem;
        margin-bottom: 0.5em;
        letter-spacing: 0.1em;
        line-height: 1;
        span {
          display: block;
          font-size: 2.5rem;
          margin-top: 0.05em;
        }
        @include sp {
          font-size: 1.125rem;
          margin-bottom: 0.75rem;
          span {
            font-size: 1.875rem;
          }
        }
      }
      h4 {
        letter-spacing: 0.1em;
      }
      .play {
        margin-top: 0.75em;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        pointer-events: all;
        @include sp {
          margin-top: 1em;
        }
        img {
          width: 1.5rem;
          height: auto;
          margin-right: 0.75em;
          filter: invert(1);
        }
      }
    }
  }
}
</style>
